<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/team`"
                title="Users"
                :columns="columns"
                routerpath="/setup/addusers"
                :formOptions="formOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID'
          },
          hidden: true
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search name'
          }
        },
        {
          label: 'Mobile',
          field: 'mobile',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Mobile'
          }
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email'
          }
        },
        {
          label: 'Address',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Address'
          }
        },
        {
          label: 'Area',
          field: 'area',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Area'
          }
        },
        {
          label: 'City',
          field: 'city',
          filterOptions: {
            enabled: true,
            placeholder: 'Search City'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Users' : 'Add Users'}`,
        submitRouterPath: '/setup/users',
        usersDropdown: true,
        branchDropdown: true,
        designationDropdown: true,
        method: 'post',
        action: 'add',
        getEditValue: `${baseApi}/getusersbyid`,
        gridForm: true,
        url: `${baseApi}/team`,
        inputFields: [
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Name',
            name: 'name',
            value: '',
            type: 'text',
            placeholder: 'Enter Name',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Email',
            name: 'email',
            value: '',
            type: 'email',
            placeholder: 'Enter Email Address',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Mobile',
            name: 'mobilenumber',
            value: '',
            type: 'number',
            placeholder: 'Enter Mobile',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Address',
            name: 'address',
            value: '',
            type: 'text',
            placeholder: 'Enter Address',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Area',
            name: 'area',
            value: '',
            type: 'text',
            placeholder: 'Enter Area',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'State',
            name: 'state',
            value: '',
            type: 'text',
            placeholder: 'Enter state',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            placeholder: 'Enter city',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Pin Code',
            name: 'pincode',
            value: '',
            type: 'number',
            placeholder: 'Enter Pincode',
            class: 'col-md-6',
            required: true,
            rules: 'digits:6'
          },
          {
            label: 'Gender',
            name: 'gender',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            required: true,
            responseValue: 'name',
            // inputStyle: "height: 25px; padding: 5px",
            options: [{ name: 'Male' }, { name: 'Female' }]
          },
          {
            label: 'User Name',
            name: 'username',
            value: '',
            type: 'text',
            placeholder: 'Enter username',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Password',
            name: 'password',
            value: '',
            type: 'password',
            placeholder: 'Enter Password',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'joining Date',
            name: 'joiningdate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Enter joining date',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Resign Date',
            name: 'resigndate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Enter resign date',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Reporting Authority',
            name: 'parent',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'parent',
            url: 'getUsers',
            required: true,
            responseValue: 'id',
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Designation',
            name: 'designation',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'designation',
            url: 'getPosition',
            required: true,
            responseValue: 'id',
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Branch',
            name: 'branch',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'branch',
            url: 'branch',
            responseValue: 'id',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          }
        ]
      }
    }
  },
  beforeMount () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
